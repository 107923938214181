<template>
  <div>
    <c-table
      ref="table"
      :title="imprTabInfo.gridItem.title"
      :columns="gridItemColumns"
      :gridHeight="gridHeight"
      :data="gridItemData"
      :merge="imprTabInfo.gridItem.merge"
      :filtering="false"
      :columnSetting="false"
      :usePaging="false"
      :editable="!imprTabInfo.disabled"
    >
      <template v-slot:customArea="{ props }">
        <template v-if="props.row['sopImprovementIds'] && props.row['ibmStepNames']">
          <q-chip
            v-for="(item, index) in $comm.ibmTagItems(props.row)"
            :key="index"
            :color="item.color"
            :clickable="true"
            text-color="white"
            style="margin-bottom:4px !important;"
            @click="linkClick(props.row, item.ibmClassCd, item.sopImprovementId, 'item')">
            {{item.title}}
            <c-assess-tooltip 
              :ibmTooltip="item.ibmTooltip"
              :ibmClassCd="item.ibmClassCd"
            />
          </q-chip>
        </template>
      </template>
    </c-table>
    <c-table
      ref="gridOther"
      :title="imprTabInfo.gridOther.title"
      :columns="gridOther.columns"
      :data="gridOther.data"
      :gridHeight="gridHeight"
      :usePaging="false"
      :filtering="false"
      :columnSetting="false"
      @linkClick="(row, col, idx) => linkClick(row, row.ibmClassCd, row.sopImprovementId, 'other')"
    >
      <template slot="table-button">
        <q-btn-group outline >
          <c-btn 
            v-if="!imprTabInfo.disabled&&imprTabInfo.isOld" 
            label="개선요청" 
            icon="add" 
            @btnClicked="addImpr" />
          <c-btn 
            v-if="!imprTabInfo.disabled&&imprTabInfo.isOld" 
            label="즉시조치" 
            icon="add" 
            @btnClicked="addImmImpr" />
        </q-btn-group>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import { uid } from 'quasar';
import selectConfig from '@/js/selectConfig';
export default {
  name: 'imprTab',
  props: {
    imprTabInfo: {
      type: Object,
      default: () => ({
        key: '', // 
        ibmTaskTypeCd: '',
        requestContents: '',
        disabled: false,
        isOld: false,
        gridItem: {
          listUrl: '',
          param: null,
          title: '항목 별 개선사항 목록',
          data: [],
          merge: [],
          columns: [],
          research: {
            impr: '',
            item: '',
          },
        },
        gridOther: {
          title: '항목외 개선 목록',
        },
      }),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    contentHeight: null,
  },
  data() {
    return {
      gridItem: {
        data: [],
      },
      gridOther: {
        columns: [
          {
            name: 'plantName',
            field: 'plantName',
            label: '사업장',
            align: 'center',
            style: 'width:100px',
            sortable: false,
          },
          {
            name: 'ibmClassName',
            field: 'ibmClassName',
            label: '구분',
            align: 'center',
            style: 'width:80px',
            sortable: false,
          },
          {
            name: 'actionDeptName',
            field: 'actionDeptName',
            label: '조치부서',
            align: 'center',
            style: 'width:120px',
            sortable: false,
          },
          {
            name: 'ibmTitle',
            field: 'ibmTitle',
            label: '제목',
            align: 'left',
            style: 'width:300px',
            sortable: false,
            type: 'link',
          },
          {
            name: 'actionContents',
            field: 'actionContents',
            label: '조치내용',
            align: 'left',
            style: 'width:400px',
            sortable: false,
          },
          {
            name: 'actionCompleteDate',
            field: 'actionCompleteDate',
            label: '조치완료일',
            align: 'center',
            style: 'width:120px',
            sortable: false,
          },
        ],
        data: [],
      },
      requestImprRow: null,
      editable: true,
      impListUrl: '',
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '80%',
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  watch: {
    'imprTabInfo.gridItem.research.impr'() {
      this.getList();
    }
  },
  computed: {
    gridHeight() {
      return this.contentHeight && (this.contentHeight - 500) > 400 ? String(this.contentHeight - 500) + 'px' : '400px';
    },
    gridItemColumns() {
      return this.$_.concat(this.imprTabInfo.gridItem.columns, [{
        name: 'customCol',
        field: 'customCol',
        label: '개선/진행상태',
        align: 'center',
        style: 'width:200px',
        type: 'custom',
        sortable: false
      }])
    },
    gridItemData() {
      return this.imprTabInfo.gridItem.data && this.imprTabInfo.gridItem.data.length > 0 
        ? this.imprTabInfo.gridItem.data : this.gridItem.data
    }
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.impListUrl = selectConfig.sop.ibm.improve.others.url;
      // code setting
      // list setting
      this.getList();
      this.getImprList();
    },
    getList() {
      if (this.imprTabInfo.gridItem.listUrl) {
        this.$http.url = this.imprTabInfo.gridItem.listUrl;
        this.$http.type = 'GET';
        this.$http.param = this.imprTabInfo.gridItem.param
        this.$http.request((_result) => {
          this.gridItem.data = _result.data;
        },);
      }
    },
    getImprList() {
      if (this.imprTabInfo.key) {
        this.$http.url = this.impListUrl;
        this.$http.param = {
          ibmTaskTypeCd: this.imprTabInfo.ibmTaskTypeCd, 
          relationTableKey: this.imprTabInfo.key
        };
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.gridOther.data = _result.data;
        },);
      }
    },
    /* eslint-disable no-unused-vars */
    linkClick(row, ibmClassCd, sopImprovementId, flag) {
      if (ibmClassCd === 'IC00000001') {
        this.popupOptions.title = '개선';
        this.popupOptions.target = () => import(`${'@/pages/sop/ibm/imprDetail.vue'}`);
      } else {
        this.popupOptions.title = '즉시조치';
        this.popupOptions.target = () => import(`${'@/pages/sop/ibm/imprImmDetail.vue'}`);
      }
      this.popupOptions.param = {
        sopImprovementId: sopImprovementId,
      };
      this.popupOptions.width = '80%';
      this.popupOptions.visible = true;
      if (flag === 'item') {
        this.requestImprRow = row
        this.popupOptions.closeCallback = this.closeImprPopup;
      } else {
        this.popupOptions.closeCallback = this.closeImprOtherPopup;
      }
    },
    closeImprPopup(result, flag) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (result && result.col1) {
        if (this.imprTabInfo.gridItem.research) {
          this.$set(this.imprTabInfo.gridItem.research, 'item', uid())
        }
        if (flag !== 'delete') {
          let splitId = this.$_.split(this.requestImprRow.sopImprovementIds, ',');
          let splitStep = this.$_.split(this.requestImprRow.ibmStepNames, ',');
          if (splitId && splitId.length > 0) {
            let idIndex = this.$_.indexOf(splitId, result.col1.sopImprovementId);
            splitStep[idIndex] = result.col1.ibmStepName;
            this.requestImprRow.ibmStepNames = splitStep.toString();
          }
        } else {
          let splitId = this.$_.split(this.requestImprRow.sopImprovementIds, ',');
          let splitStep = this.$_.split(this.requestImprRow.ibmStepNames, ',');
          let splitClass = this.$_.split(this.requestImprRow.ibmClassCds, ',');
          let splitTooltip = this.$_.split(this.requestImprRow.ibmTooltip, '│');
          if (splitId && splitId.length > 0) {
            let idIndex = this.$_.indexOf(splitId, result.col1.sopImprovementId);
            splitId.splice(idIndex, 1)
            splitStep.splice(idIndex, 1)
            splitClass.splice(idIndex, 1)
            splitTooltip.splice(idIndex, 1)
            this.$set(this.requestImprRow, 'sopImprovementIds', splitId.toString())
            this.$set(this.requestImprRow, 'ibmStepNames', splitStep.toString())
            this.$set(this.requestImprRow, 'ibmClassCds', splitClass.toString())
            this.$set(this.requestImprRow, 'ibmTooltip', splitTooltip.join('│'))
          }
        }
        this.$refs['table'].$refs['compo-table'].resetVirtualScroll();
        this.getList();
      }
    },
    closeImprOtherPopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;

      this.getImprList();
    },
    /* eslint-disable no-unused-vars */
    addImpr() {
      this.popupOptions.title = '개선 요청';
      this.popupOptions.param = {
        requestContents: this.imprTabInfo.requestContents,
        relationTableKey: this.imprTabInfo.key,
        ibmTaskTypeCd: this.imprTabInfo.ibmTaskTypeCd,
      };
      this.popupOptions.target = () => import(`${'@/pages/sop/ibm/imprDetail.vue'}`);
      this.popupOptions.width = '80%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeAddImprPopup;
    },
    addImmImpr() {
      this.popupOptions.title = '즉시조치';
      this.popupOptions.param = {
        requestContents: this.imprTabInfo.requestContents,
        relationTableKey: this.imprTabInfo.key,
        ibmTaskTypeCd: this.imprTabInfo.ibmTaskTypeCd,
      };
      this.popupOptions.target = () => import(`${'@/pages/sop/ibm/imprImmDetail.vue'}`);
      this.popupOptions.width = '80%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeAddImprPopup;
    },
    closeAddImprPopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getImprList();
    }
  }
};
</script>
