var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("c-table", {
        ref: "table",
        attrs: {
          title: _vm.imprTabInfo.gridItem.title,
          columns: _vm.gridItemColumns,
          gridHeight: _vm.gridHeight,
          data: _vm.gridItemData,
          merge: _vm.imprTabInfo.gridItem.merge,
          filtering: false,
          columnSetting: false,
          usePaging: false,
          editable: !_vm.imprTabInfo.disabled,
        },
        scopedSlots: _vm._u([
          {
            key: "customArea",
            fn: function ({ props }) {
              return [
                props.row["sopImprovementIds"] && props.row["ibmStepNames"]
                  ? _vm._l(
                      _vm.$comm.ibmTagItems(props.row),
                      function (item, index) {
                        return _c(
                          "q-chip",
                          {
                            key: index,
                            staticStyle: { "margin-bottom": "4px !important" },
                            attrs: {
                              color: item.color,
                              clickable: true,
                              "text-color": "white",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.linkClick(
                                  props.row,
                                  item.ibmClassCd,
                                  item.sopImprovementId,
                                  "item"
                                )
                              },
                            },
                          },
                          [
                            _vm._v(" " + _vm._s(item.title) + " "),
                            _c("c-assess-tooltip", {
                              attrs: {
                                ibmTooltip: item.ibmTooltip,
                                ibmClassCd: item.ibmClassCd,
                              },
                            }),
                          ],
                          1
                        )
                      }
                    )
                  : _vm._e(),
              ]
            },
          },
        ]),
      }),
      _c(
        "c-table",
        {
          ref: "gridOther",
          attrs: {
            title: _vm.imprTabInfo.gridOther.title,
            columns: _vm.gridOther.columns,
            data: _vm.gridOther.data,
            gridHeight: _vm.gridHeight,
            usePaging: false,
            filtering: false,
            columnSetting: false,
          },
          on: {
            linkClick: (row, col, idx) =>
              _vm.linkClick(row, row.ibmClassCd, row.sopImprovementId, "other"),
          },
        },
        [
          _c(
            "template",
            { slot: "table-button" },
            [
              _c(
                "q-btn-group",
                { attrs: { outline: "" } },
                [
                  !_vm.imprTabInfo.disabled && _vm.imprTabInfo.isOld
                    ? _c("c-btn", {
                        attrs: { label: "개선요청", icon: "add" },
                        on: { btnClicked: _vm.addImpr },
                      })
                    : _vm._e(),
                  !_vm.imprTabInfo.disabled && _vm.imprTabInfo.isOld
                    ? _c("c-btn", {
                        attrs: { label: "즉시조치", icon: "add" },
                        on: { btnClicked: _vm.addImmImpr },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        2
      ),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }